import React, {useState, useEffect} from "react";
import { Button, Form, FormControl } from "react-bootstrap";
import InputMask from 'react-input-mask';
import { Helmet } from "react-helmet";

import './styles.css';
import validatePHONE from "../../../services/validar_phone";
import validateCPF from "../../../services/validar_cpf";
import capitalizeWords from "../../../services/capitalizeWords";
import api from '../../../services/api_db';

import { FiSave } from "react-icons/fi";

import dataBanks from '../../../services/bancos.json'
import dataProfession from '../../../services/profissoes.json'

export default function User(){
    const [focused,  SetFocused] = useState({
        name: false,
        cellphone: false,
        profession: false,
        uf: false,
        city: false,
        cpf: false
    })

    const [values, setValues] = useState({
        name: {"value":"", "validated": false},
        email: {"value":"", "validated": false},
        cellphone: {"value":"", "validated": false},
        birthday: {"value":"", "validated": false},
        profession: {"value":"", "validated": false},
        uf: {"value":"", "validated": false},
        city: {"value":"", "validated": false},
    })
    const [bankValues, setBankValues] = useState({
        bank: {"value":"", "validated": false},
        agency: {"value":"", "validated": false},
        account: {"value":"", "validated": false},
        cpf: {"value":"", "validated": false},
    })

    const [isNameOnDB, setIsNameOnDB] = useState(false)
    const [isCpfOnDB, setIsCpfOnDB] = useState(false)

    //telefone
    const [isCellPhone, setIsCellPhone] = useState("");
    //estado e cidade
    const [ufs, setUfs] = useState([]);
    const [cities, setCities] = useState([]);

    const bancos = dataBanks.filter(item => item.Type === "Banco Múltiplo");

    const onChangeValues = (e) => {
        if(e.target.name === "cellphone"){
            setValues({ ...values, [e.target.name]: {"value": e.target.value, "validated": validatePHONE(e.target.value)}});
            setIsCellPhone(values.cellphone.value.charAt(5) === '9');
        }if(e.target.name === "uf"){
            setValues({ ...values, [e.target.name]: {"value": e.target.value === "Selecione" ? "" : e.target.value, "validated": e.target.value === "Selecione" ? false : true }})
        }if(e.target.name === "city"){
            setValues({ ...values, [e.target.name]: {"value": e.target.value === "Selecione" ? "" : e.target.value, "validated": e.target.value === "" ? false : true}})
        }if(e.target.name === "name"){
            var palavras = e.target.value.trim().split(" ");
            var isValid = palavras.length > 1;
            setValues({ ...values, [e.target.name]: {"value": e.target.value, "validated": isValid}})
        }if(e.target.name === "profession"){
            setValues({ ...values, [e.target.name]: {"value": e.target.value, "validated": e.target.value === "" ? false : true }})
        }
    }
    const onChangeBankValues = (e) => {
        if(e.target.name === "cpf"){
            setBankValues({ ...bankValues, [e.target.name]: {"value": e.target.value, "validated": validateCPF(e.target.value)}})
        }if(e.target.name === "bank"){
            setBankValues({ ...bankValues, [e.target.name]: {"value": e.target.value, "validated": e.target.value === "" ? false : true}})
        }if(e.target.name === "agency"){
            setBankValues({ ...bankValues, [e.target.name]: {"value": e.target.value, "validated": e.target.value === "" ? false : true}})
        }if(e.target.name === "account"){
            setBankValues({ ...bankValues, [e.target.name]: {"value": e.target.value, "validated": e.target.value === "" ? false : true}})
        }
        
    }

    async function handleSubmit(e) {
        e.preventDefault();
        
        const dataUser = {
            "id_user": localStorage.getItem("userId"),
            "nome": values.name.value,
            "celular": values.cellphone.value,
            "profissao": values.profession.value,
            "uf": values.uf.value,
            "cidade": values.city.value,
            "cpf": bankValues.cpf.value,
            "banco": bankValues.bank.value,
            "agencia": bankValues.agency.value,
            "conta": bankValues.account.value
        }

        SetFocused({...focused,
            name: true,
            cellphone: true,
            profession: true,
            uf: true,
            city: true})

        if(!bankValues.cpf.value){
            if(values.name.validated === true &&
                values.cellphone.validated === true &&
                values.profession.validated === true &&
                values.uf.validated === true &&
                values.city.validated === true
                ){
                    
                try {
                    const response = await api.post('update_user', dataUser);
                    if(response.data.message === "Dados atualizados"){
                        alert("Muito obrigado por atualizar seus dados");
                        window.location.reload() 
                        
                    }else{
                        alert("Erro ao atualizar, tente novamente mais tarde");
                    }
                    
                }catch(err){
                    alert("Erro ao cadastrar. Tente novamente mais tarde.")
                    console.log(err)
                }
            }else{
                alert('Por favor, preencha todos os campos obrigatórios');
            }
        }else{
            if(values.name.validated === true &&
                values.cellphone.validated === true &&
                values.profession.validated === true &&
                values.uf.validated === true &&
                values.city.validated === true &&
                bankValues.cpf.validated === true
                ){
                try {
                    const response = await api.post('update_user', dataUser);
                    if(response.data.message === "Dados atualizados"){
                        alert("Muito obrigado por atualizar seus dados");
                        
                    }else{
                        alert("Erro ao atualizar, tente novamente mais tarde");
                    }
                    
                }catch(err){
                    alert("Erro ao cadastrar. Tente novamente mais tarde.")
                    console.log(err)
                }
            }else{
                alert('Por favor, preencha todos os campos obrigatórios e confira se o CPF é valido');
            }
        }
        
    }
    
    

    //Estados e cidades
    useEffect(() => {
        try {
            if(!localStorage.getItem("userId")){
                return
            }else{
                api.post('user', {"id_user": localStorage.getItem("userId")}).then(response => {
                    setValues({ ...values, 
                        "name": {"value": response.data[0].nome, "validated": !response.data[0].nome ? false : true},
                        "email": {"value": response.data[0].email, "validated": !response.data[0].email ? false : true},
                        "cellphone": {"value": response.data[0].celular, "validated": !response.data[0].celular ? false : true},
                        "birthday": {"value": response.data[0].nascimento, "validated": !response.data[0].nascimento ? false : true},
                        "profession": {"value": response.data[0].profissao, "validated": !response.data[0].profissao ? false : true},
                        "uf": {"value": response.data[0].uf, "validated": !response.data[0].uf ? false : true},
                        "city": {"value": response.data[0].cidade, "validated": !response.data[0].cidade ? false : true},
                    });

                    setBankValues({ ...bankValues,
                        "cpf": {"value": response.data[0].cpf, "validated": !response.data[0].cpf ? false : true},
                        "bank": {"value": response.data[0].banco, "validated": !response.data[0].cpf ? false : true},
                        "agency": {"value": response.data[0].agencia, "validated": !response.data[0].cpf ? false : true},
                        "account": {"value": response.data[0].conta, "validated": !response.data[0].cpf ? false : true}
                    });
                        
                    setIsNameOnDB(response.data[0].nome === '' ? false : true);
                    setIsCpfOnDB(response.data[0].cpf === '' ? false : true);
                    
                })}
                
        }catch(err){
            alert("Erro. Dados do usuario")
            console.log(err)
        }
    }, [])
    
    useEffect(() => {
        async function fetchOptions() {
            const response = await fetch('https://servicodados.ibge.gov.br/api/v1/localidades/estados');
            const data = await response.json();
            
            // Encontra o objeto com o ID 41 e o remove do array
            const prState = data.find(({ id }) => id === 41);
            data.splice(data.indexOf(prState), 1);
          
            // Coloca o objeto com o ID 41 na primeira posição do array
            const options = [prState, ...data].map(({ id, sigla }) => ({ value: id, label: sigla }));
            setUfs(options);
          }
          
          fetchOptions();
    }, []);

    useEffect(() => {
        async function fetchCities() {
            
            
            if (values.uf.value === '') {
                setCities([]);
                
                setValues({ ...values, city: {"value": "", "validated": false}})
                
                
                return; // Retorna imediatamente para evitar a chamada desnecessária da API.
              }else {
                
              const response = await fetch(
                `https://servicodados.ibge.gov.br/api/v1/localidades/estados/${values.uf.value}/municipios`
              );
              
              let cities = await response.json();
              
              // Verifica se UF selecionado é "PR"
              if (values.uf.value === "PR") {
                
                // Encontra o objeto com o ID 4106902 e o remove do array
                const cwbCyty = cities.find(({ id }) => id === 4106902);
                
                cities.splice(cities.indexOf(cwbCyty), 1);
                // Coloca o objeto com o ID 4106902 na primeira posição do array
                const options = [cwbCyty, ...cities];
                setCities(options);
                
                // Filtra a lista de cidades para garantir que "Curitiba" seja a primeira opção
                //cities = cities.filter(city => city.id === "4106902").concat(cities.filter(city => city.id !== "4106902"));
              }else{
                setCities(cities);
              }
              
            }
        }

        fetchCities();
    }, [values.uf.value]);

    const handleFocus = (e) => {
        SetFocused({...focused, [e.target.name]: true});
    
    }

   
    


    return(
        <div className="user-container">
            <Helmet>
                <title>
                    Conta de usuário - www.indiqueumimovel.com
                </title>
            </Helmet>
            <div>
                <h1>Conta de usuario</h1>
                <Button onClick={handleSubmit} variant="warning" >
                    <FiSave strokeWidth={3} size={18}/> Salvar
                </Button>
            </div>

            <Form className="user-dadosPessoais">
                <div className="user-subtitulo">
                        <h2>Dados Pessoais</h2>
                        <p>*Preenchimento obrigatório</p>
                </div>

                <div className="linha1">
                    <Form.Group className="mb-3">
                        <Form.Label>
                            Nome Completo
                        </Form.Label>
                        <Form.Control 
                            className={!values.name.validated ? "invalid" : ""}
                            name="name"
                            value={values.name.value}
                            onChange={onChangeValues}
                            placeholder="Nome completo"
                            disabled={isNameOnDB}
                            onBlur={handleFocus}
                            focused={focused.name.toString()}
                            
                        />
                        <Form.Label className="errorMessage">
                            É preciso Nome e Sobrenome
                        </Form.Label>
                    </Form.Group>
                    
                    <Form.Group className="mb-3">
                        <Form.Label>
                            Nascimento
                        </Form.Label>
                        <Form.Control
                            name="birthday"
                            value={values.birthday.value}
                            placeholder="DD/MM/AAAA"
                            type="date"
                            disabled={true}
                            />
                    </Form.Group>
                </div>
                <div className="linha2">
                    <Form.Group className="mb-3">
                        <Form.Label>
                            Profissão
                        </Form.Label>
                        <Form.Select 
                            className={!values.profession.validated ? "invalid" : ""}
                            name="profession"
                            value={values.profession.value}
                            onChange={onChangeValues}
                            aria-label="profissão"
                            onBlur={handleFocus}
                            focused={focused.profession.toString()}
                           
                        >
                            <option value="" >Selecione</option>
                            {dataProfession.sort((a, b) => a.profissao.localeCompare(b.profissao)).map((pf) => (
                                <option key={pf.cod} value={pf.profissao.charAt(0).toUpperCase() + pf.profissao.slice(1)}>
                                {pf.profissao.charAt(0).toUpperCase() + pf.profissao.slice(1)}
                                </option>
                            ))}
                        </Form.Select>
                        <Form.Label className="errorMessage">
                            Preencha sua profissão por favor
                        </Form.Label>
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <Form.Label>
                            {"Estado"}
                        </Form.Label>
                        <Form.Select aria-label="Selecione uma opção"
                            className={!values.uf.validated ? "invalid" : ""}
                            name="uf"
                            value={values.uf.value}
                            onChange={onChangeValues}
                            onBlur={handleFocus}
                            focused={focused.uf.toString()}
                            >
        
                            <option>Selecione</option>
                            {ufs.map((ufs) => (
                                <option key={ufs.id} value={ufs.id}>{ufs.label}</option>
                            ))}
                        </Form.Select>
                        <Form.Label className="errorMessage">
                            Selecione seu Estado
                        </Form.Label>
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <Form.Label>
                            {"Cidade"}
                        </Form.Label>
                        <Form.Select
                            className={!values.city.validated ? "invalid" : ""}
                            name="city"
                            value={values.city.value}
                            onChange={onChangeValues}
                            aria-label="cidade" 
                            disabled={!values.uf.value}
                            onBlur={handleFocus}
                            focused={focused.city.toString()}
                            >
                            <option value="">Selecione</option>
                            {cities.map((city) => (
                                    <option key={city.id} value={city.nome}>
                                {city.nome}
                                </option>
                                ))}
                        </Form.Select>
                        <Form.Label className="errorMessage">
                            Selecione sua Cidade
                        </Form.Label>
                    </Form.Group>
                    </div>
                    <div className="linha3">
                    <Form.Group className="mb-3">
                        <Form.Label>
                            E-mail
                        </Form.Label>
                        <Form.Control
                            disabled
                            value={values.email.value}
                            placeholder="digite seu e-mail"
                                
                        />
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <Form.Label>
                            {"Telefone (Com Whatsapp de preferencia**)"}
                        </Form.Label>
                        <InputMask
                            className={!values.cellphone.validated ? "invalid" : ""}
                            name="cellphone"
                            placeholder="(xx) xxxx-xxxx"
                            mask={isCellPhone ? "(99) 99999-9999" : "(99) 9999-9999"}
                            value={values.cellphone.value}
                            onChange={onChangeValues}
                            onBlur={handleFocus}
                            focused={focused.cellphone.toString()}
                            >
                            
                            {(inputProps) => (
                                <FormControl
                                {...inputProps}
                                type="text"
                                
                                
                                />
                            )}
                        </InputMask>
                        <Form.Label className="errorMessage">
                            Digite um telefone valido por favor
                        </Form.Label>
                    </Form.Group>
                    </div>
                
                <div className="p-text">
                    <p>**O WhatsApp é nosso principal meio de comunicação, imprecindivel para uma melhor experiencia.</p>
                </div>
                
            </Form>


            <Form className="user-dadosBancarios">
                <div className="user-subtitulo">
                    <h2>Dados Bancários</h2>
                    <p> *Não é obrigatório para começar a indicar, porém é obrigatório na ocasião de recebimento.
                        Os dados bancários devem ser do titular desta conta e o nome utilizado na transferencia será a do cadastro pessoal.</p>
                </div>
                <div className="linha1">
                    <Form.Group className="mb-3">
                        <Form.Label>
                            Banco
                        </Form.Label>
                        <Form.Select 
                            aria-label="banco"
                            name="bank"
                            value={bankValues.bank.value}
                            onChange={onChangeBankValues}
                        >   
                            <option value="">Selecione</option>
                            {bancos.map((bank) => (
                                    <option key={bank.COMPE} value={bank.COMPE}>
                                        {bank.COMPE + " - "+ capitalizeWords(bank.LongName)}
                                    </option>
                                ))}
                        </Form.Select>
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <Form.Label>
                            Agência
                        </Form.Label>
                        <InputMask
                            name="agency"
                            value={bankValues.agency.value}
                            onChange={onChangeBankValues}
                            className=""
                            placeholder="xxxx"
                            mask="9999"
                        
                            >
                            {(inputProps) => (
                                <FormControl
                                {...inputProps}
                                type="text"
                                />
                            )}
                        </InputMask>
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <Form.Label>
                            Conta
                        </Form.Label>
                        <InputMask
                            name="account"
                            onChange={onChangeBankValues}
                            value={bankValues.account.value}
                            placeholder="xxxxxxxx-x"
                            mask="99999999-9"
                        
                            >
                            {(inputProps) => (
                                <FormControl
                                {...inputProps}
                                type="text"
                                />
                            )}
                        </InputMask>
                    </Form.Group>
                <Form.Group className="mb-3">
                    <Form.Label>
                        C.P.F
                    </Form.Label>
                    <InputMask
                        className={!bankValues.cpf.validated ? "invalid" : ""}
                        name="cpf"
                        placeholder="xxx.xxx.xxx-xx"
                        mask="999.999.999-99"
                        value={bankValues.cpf.value}
                        onChange={onChangeBankValues}
                        disabled={isCpfOnDB}
                        onBlur={handleFocus}
                        focused={focused.cpf.toString()}
                        
                        >
                        
                        {(inputProps) => (
                            <FormControl
                            {...inputProps}
                            type="text"
                            />
                        )}
                    </InputMask>
                    <Form.Label className="errorMessage">
                        Digite um CPF válido por favor
                    </Form.Label>
                </Form.Group>
                </div>
                
                
            </Form>
        </div>
    )        
}