import React, { useState, useEffect } from 'react';
import {Link} from 'react-router-dom';

import api from '../services/api_db';

import { FiUsers, FiFileText, FiSettings, FiChevronsDown, FiChevronsUp, FiDollarSign } from 'react-icons/fi'

export default function Menu({activeIndex, setActiveIndex}) {
  const [isExpanded, setIsExpanded] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);
  const userId = localStorage.getItem('userId')

    useEffect(()=>{
        try{
            api.get('isadmin',{
                headers: {
                    'Authorization': userId,
            }})
            .then(response => {
                const isadmin = response.data.oauth;
                setIsAdmin(isadmin);
                
            });
        }catch(err){
            console.log("erro ao obter o username")
        }
    },[])

    //Rotas
    const routeIncidents = '/indicacoes';
    const routeReportLeads = '/relatorios/leads';
    const routeReportUsers = '/relatorios/usuarios';
    const routeUser = '/usuario';
    const routeValue = '/tabelas';
    
    //funções
    const handleClick = (index) => {
        setActiveIndex(index);
    }

    
      
      

    

  return (
        
        <div className='menu-container '>
            <div className="menu-dsk">
                <Link to={routeIncidents} className={'menu-link ' + (activeIndex === 0 ? "active" : "")} onClick={() => handleClick(0)} >
                    <FiUsers size={18} strokeWidth={3}/> Indicações
                </Link>
                <Link to={routeValue} className={'menu-link ' + (activeIndex === 1 ? "active" : "")} onClick={() => handleClick(1)} >
                    <FiDollarSign size={18} strokeWidth={3}/> Valores
                </Link>
                {isAdmin && ( 
                    <div className="togle-menu">
                        <p href='#' className='menu-link' onClick={() => setIsExpanded(!isExpanded)}>
                            {isExpanded ? 
                            <span>
                            <FiFileText size={18} strokeWidth={3} /> Relatórios 
                            <FiChevronsUp size={18} strokeWidth={3}/>
                            </span> 
                            :
                            <span>
                                <FiFileText size={18} strokeWidth={3} /> Relatórios 
                                <FiChevronsDown size={18} strokeWidth={3}/>
                            </span>
                            }
                        </p>
                        {isExpanded && (
                            <div >
                                <Link to={routeReportLeads} className={'menu-link menu-toggle ' + (activeIndex === 2 ? "active" : "")} onClick={() => handleClick(2)}>Lead's</Link>
                                <Link to={routeReportUsers} className={'menu-link menu-toggle ' + (activeIndex === 3 ? "active" : "")} onClick={() => handleClick(3)}>Usuarios</Link>
                            </div>
                        )}
                    </div>
                )} 
                
                <Link to={routeUser} onClick={() => handleClick(4)} className={'menu-link ' + (activeIndex === 4 ? "active" : "")}><FiSettings size={18} strokeWidth={3} /> Conta</Link>                                                      
            </div>
        </div>
            
    );  
}

