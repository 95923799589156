export default function validateCPF(cpf) {
      //extrai os dígitos do CPF
      const num1 = cpf.charAt(0);
      const num2 = cpf.charAt(1);
      const num3 = cpf.charAt(2);
      const num4 = cpf.charAt(4);
      const num5 = cpf.charAt(5);
      const num6 = cpf.charAt(6);
      const num7 = cpf.charAt(8);
      const num8 = cpf.charAt(9);
      const num9 = cpf.charAt(10);
      const num10 = cpf.charAt(12);
      const num11 = cpf.charAt(13);

      //Validação dos CPFs inválidos conhecidos
      if (num1 === num2 && num2 === num3 && num3 === num4 && num4 === num5 && num5 === num6 && num6 === num7 && num7 === num8 && num8 === num9 && num9 === num10 && num10 === num11) {
        return false;
      } else {
        
        let soma1 = num1 * 10 + num2 * 9 + num3 * 8 + num4 * 7 + num5 * 6 + num6 * 5 + num7 * 4 + num8 * 3 + num9 * 2;
        let resto1 = (soma1 * 10) % 11;


        if (resto1 === 10) {
            resto1 = 0;
        }
        
        let soma2 = num1 * 11 + num2 * 10 + num3 * 9 + num4 * 8 + num5 * 7 + num6 * 6 + num7 * 5 + num8 * 4 + num9 * 3 + num10 * 2;
        let resto2 = (soma2 * 10) % 11;

        if (resto2 === 10) {
          resto2 = 0;
        }

        if (resto1.toString() === num10 && resto2.toString() === num11) {
          return true;
        } else {
          return false;
        }
    }}