import React, {useState, useEffect, useRef} from "react";
import { Helmet } from 'react-helmet';


import InputMask from 'react-input-mask';
import {useNavigate} from 'react-router-dom';

import './styles.css';
import api from '../../../services/api_db';

import { Badge, Button, InputGroup, Form, Modal, FormControl, FloatingLabel} from 'react-bootstrap';
import { FiEdit, FiMail, FiPhone, FiSearch, FiSend, FiUser, FiUserPlus, FiX } from "react-icons/fi";
import validatePHONE from "../../../services/validar_phone";
import validateEMAIL from "../../../services/validar_email";


export default function Incidents() {
    const [countLeads, setCountLeads] = useState(0);
    const userId = localStorage.getItem('userId');
    const [userName, setUserName] = useState(localStorage.getItem('userName'));
    const [leads, setLeads] = useState([]);
    const navigate = useNavigate();
    const [focused,  SetFocused] = useState({
        nome: false,
        telefone: false,
        email: false,
        uf: false,
        cidade: false,
        interesse: false,
        comentario_user: false
    })

    const handleFocus = (e) => {
        SetFocused({...focused, [e.target.name]: true});
    }

    useEffect(()=>{
        if(!userId){
            navigate('/login')
        }else{
            if(!userName){
                try{
                    api.get('users')
                    .then(response => {
                        const user = response.data.find(user => user.id_user === userId);
                        setUserName(user ? user.nome.split(' ')[0] : '');
                        localStorage.setItem("userName", user ? user.nome.split(' ')[0] : '' )
                        if(user.nome === ""){
                            alert("Você precisa completar seus Dados Pessoais para começar a indicar")
                            navigate('/usuario')
                        }
                    });
                    
                }catch(err){
                    console.log("erro ao obter o username")
                }
                
            }}
    }, [])

    useEffect(()=>{
        api.get('leads', {
            headers: {
                'Authorization': userId,
        }}).then(response => {
                setLeads(response.data)
                setCountLeads(response.data.length)
            });
    },[handleSubmitNC])


    //pesquisa
    function Pesquisar(string) {
        const tabela = document.getElementsByTagName("ul")[0];
        const linhas = tabela.getElementsByTagName("li");
        for (let r = 0; r < linhas.length; r++) {
            const dados = linhas[r].getElementsByTagName("strong");
            let encontrei = false;
            for (let d = 0; d < dados.length; d++) {
                if (dados[d].innerText.toLowerCase().indexOf(string.toLowerCase()) >= 0) {
                    encontrei = true;
                    break;
                }
            }
            if (encontrei) {
                linhas[r].style.display = "";
            } else {
                linhas[r].style.display = "none";
            }
        }
    }

    const handleSearchInputChange = (event) => {
        const pesquisa = event.target.value;
        Pesquisar(pesquisa);
    };

    //Novo cadastro de lead
    const [ufs, setUfs] = useState([]);
    const [cities, setCities] = useState([]);
    const [isCellPhone, setIsCellPhone] = useState("");
    const [showNewCadastro, setShowNewCadastro] = useState(false);
    const handleShowNC = () => setShowNewCadastro(true);
    const handleCloseNC = () => {
        setShowNewCadastro(false);
        setCadastroLead({
            "nome": {"value":"", "validated": false},
            "telefone": {"value":"", "validated": false},
            "email": {"value":"", "validated": true},
            "uf": {"value":"", "validated": false},
            "cidade": {"value":"", "validated": false},
            "comprar": {"value": false},
            "vender": {"value": false},
            "comentario_user": {"value":"", "validated": false},  
        })
    }
    
    const [cadastroLead, setCadastroLead] = useState({
        "nome": {"value":"", "validated": false},
        "telefone": {"value":"", "validated": false},
        "email": {"value":"", "validated": true},
        "uf": {"value":"", "validated": false},
        "cidade": {"value":"", "validated": false},
        "comprar": {"value": false},
        "vender": {"value": false},
        "comentario_user": {"value":"", "validated": false},
    })

    useEffect(() => {
        async function fetchOptions() {
            const response = await fetch('https://servicodados.ibge.gov.br/api/v1/localidades/estados');
            const data = await response.json();
            
            // Encontra o objeto com o ID 41 e o remove do array
            const prState = data.find(({ id }) => id === 41);
            data.splice(data.indexOf(prState), 1);
          
            // Coloca o objeto com o ID 41 na primeira posição do array
            const options = [prState, ...data].map(({ id, sigla }) => ({ value: id, label: sigla }));
            setUfs(options);
          }
          
          fetchOptions();
    }, []);

    useEffect(() => {
        async function fetchCities() {
            
            if (cadastroLead.uf.value === "UF") {
                setCities([]);
                return; // Retorna imediatamente para evitar a chamada desnecessária da API.
              }else {
              const response = await fetch(
                `https://servicodados.ibge.gov.br/api/v1/localidades/estados/${cadastroLead.uf.value}/municipios`
              );
              let cities = await response.json();
              
              // Verifica se UF selecionado é "PR"
              if (cadastroLead.uf.value === "PR") {
                
                // Encontra o objeto com o ID 4106902 e o remove do array
                const cwbCyty = cities.find(({ id }) => id === 4106902);
                
                cities.splice(cities.indexOf(cwbCyty), 1);
                // Coloca o objeto com o ID 4106902 na primeira posição do array
                const options = [cwbCyty, ...cities];
                setCities(options);
                
                // Filtra a lista de cidades para garantir que "Curitiba" seja a primeira opção
                //cities = cities.filter(city => city.id === "4106902").concat(cities.filter(city => city.id !== "4106902"));
              }else{
                setCities(cities);
              }
              
            }
        }
          
        fetchCities();
      }, [cadastroLead.uf.value]);
    
    const onChangeNC = (e) => {
        if(e.target.name === "nome"){
            var palavras = e.target.value.trim().split(" ");
            var isValid = palavras.length > 0;
            setCadastroLead({ ...cadastroLead, [e.target.name]: {"value": e.target.value, "validated": isValid}})
        }
        if(e.target.name === "telefone"){
            setCadastroLead({ ...cadastroLead, [e.target.name]: {"value": e.target.value, "validated": validatePHONE(e.target.value)}})
            setIsCellPhone(cadastroLead.telefone.value.charAt(5) === '9')
        }
        if(e.target.name === "email"){
            setCadastroLead({ ...cadastroLead, [e.target.name]: {"value": e.target.value, "validated": e.target.value === "" ? true : validateEMAIL(e.target.value)}})
        }
        if(e.target.name === "uf"){
            setCadastroLead({ ...cadastroLead, [e.target.name]: {"value": e.target.value === "UF" ? "" : e.target.value, "validated": e.target.value === "UF" ? false : true }})
        }
        if(e.target.name === "cidade"){
            
            setCadastroLead({ ...cadastroLead, [e.target.name]: {"value": e.target.value === "Cidade" ? "" : e.target.value, "validated": e.target.value === "Cidade" ? false : true }})
        }
        if(e.target.name === "comprar"){
            setCadastroLead({ ...cadastroLead, [e.target.name]: {"value": e.target.checked}})
        }
        if(e.target.name === "vender"){
            setCadastroLead({ ...cadastroLead, [e.target.name]: {"value": e.target.checked}})
        }
        if(e.target.name === "comentario_user"){
            var palavras2 = e.target.value.trim().split(" ");
            var isValid2 = palavras2.length > 2;
            setCadastroLead({ ...cadastroLead, [e.target.name]: {"value": e.target.value, "validated": isValid2}})
        }
        
    }
    
    async function handleSubmitNC(e) {   
        e.preventDefault();
        const dataNewLead = {
            "nome": cadastroLead.nome.value,
            "telefone": cadastroLead.telefone.value,
            "email": cadastroLead.email.value,
            "uf": cadastroLead.uf.value,
            "cidade": cadastroLead.cidade.value,
            "comprar": cadastroLead.comprar.value,
            "vender": cadastroLead.vender.value,
            "comentario_user": cadastroLead.comentario_user.value,   
        }

        SetFocused({...focused,
            nome: true,
            telefone: true,
            email: true,
            uf: true,
            cidade: true,
            interesse: true,
            comentario_user: true
        })

        
        if(cadastroLead.nome.validated === true &&
            cadastroLead.telefone.validated === true &&
            cadastroLead.email.validated === true &&
            cadastroLead.uf.validated === true &&
            cadastroLead.cidade.validated === true &&
            cadastroLead.comentario_user.validated === true &&
            (cadastroLead.comprar.value === true || cadastroLead.vender.value === true)
            ){ 
                try {
                    const response = await api.post('lead', dataNewLead, {
                        headers: {"authorization": userId} });
                    if(response.data.message === "Lead Salvo"){
                        alert("Cadastrado com sucesso");
                        handleCloseNC();
                    }
                    else{
                        alert("Pessoa já cadastrada");
                    }
                    
                }catch(err){
                    alert("Erro ao cadastrar. Tente novamente mais tarde.")
                    console.log(err)
                }
        }else{
            alert('Por favor, preencha todos os campos obrigatórios');
        }
    };
     
    // visualizat cadastro
    const [messageToSend, setMessageToSend] = useState('');
    const [showCadastro, setShowCadastro] = useState(false);
    const handleClose = () => setShowCadastro(false);

    const [leadView, setLeadView] = useState({
        "id": "",
        "nome": "",
        "telefone": "",
        "email": "",
        "menssagens": []
    });
    
    

    async function handleShow(int) {
        setShowCadastro(true);
        const lead = leads.find(({ id }) => id === int);
        const mensagens = await api.get('msg_lead', {
            headers: {
                authorization: userId,
                id: lead.id
            }
        })
        
        
        setLeadView({
            "id": lead.id,
            "nome": lead.nome,
            "email": lead.email,
            "telefone": lead.telefone,
            "menssagens": mensagens.data
        })
        
        
    } 


    function Message(props) {
        return (
          <div className={props.sender === "Eu" ? "msg align-right strong-hide" : props.sender === "system" ? "msg system-align-center strong-hide" : "msg"}>
            <strong className={props.className}>{props.sender}: </strong>
            <p>{props.text}</p>
          </div>
        );
    }

    function MessageList(props) {
        const scrollRef = useRef(null);

        useEffect(() => {
            const objDiv = scrollRef.current;
            objDiv.scrollTop = objDiv.scrollHeight;
            
        }, []);

        return (
          <div className="text-box" ref={scrollRef} >
            
            {leadView.menssagens.map((message, index) => {
            const isSameSender = index > 0 && leadView.menssagens[index - 1].sender === message.sender;
            
            return(
                <div className="messages_wrap">
                    <Message className={isSameSender ? "same-sender" : ""} key={index} sender={message.sender} text={message.text} />
                </div>
            )})}
          </div>
          
        );
    }

    async function handleMessageSubmit(e) {
        e.preventDefault();

        const data_msg = {
            "message": messageToSend,
        }
        try {
            const response = await api.post('msg_lead', data_msg, {
                headers: {
                    "authorization": userId,
                    "id": leadView.id
                }
            })
            if(response.data.message === "ok"){
                setMessageToSend("")
                const mensagens = await api.get('msg_lead', {
                    headers: {
                        authorization: userId,
                        id: leadView.id
                    }
                })
                setLeadView({ ...leadView, "menssagens": mensagens.data
                })
            }
            else{
                alert("erro");
            }
            
        }catch(err){
            alert("Erro ao cadastrar. Tente novamente mais tarde.")
            console.log(err)
        }
        
    };

    
    
    
    return (
        
            <div className="incidents-container">
                <Helmet>
                    <title>Indicações - www.indiqueumimovel.com</title>
                </Helmet>
                <div>
                    <h1>Indicações</h1>
                    <Button variant="warning" onClick={handleShowNC}><FiUserPlus strokeWidth={3} size={18}/> INDICAR</Button>
                </div>
                <div className="search-container">
                    <InputGroup className="mb-3" >
                        <InputGroup.Text className="basic-addon1" id="basic-addon1"><FiSearch size={18} strokeWidth={3} /></InputGroup.Text>
                        <Form.Control
                        className="input-login"
                        placeholder="Pesquisar"
                        aria-label="Pesquisar"
                        aria-describedby="basic-addon1"
                        onChange={handleSearchInputChange}
                        />
                    </InputGroup>
                    <div className="count-container">
                        <span>total de indicações: <strong>{countLeads}</strong></span>
                    </div>
                </div>
                

                {/*Lista de indicações*/}   
                
                <ul>
                    {leads.map((lead) =>(
                        <li key={lead.id}>
                            <strong>{lead.nome}</strong>
                            <button onClick={() => handleShow(lead.id)}><FiEdit strokeWidth={3} size={18} /></button>                        
                            <div>
                                <strong>Status :</strong>
                                <Badge className="bdg" bg="secondary">{lead.status}</Badge>
                            </div>
                            <div>
                                <strong>Data da atualização :</strong>
                                <p>{lead.data_at}</p>
                            </div>
                            <p className="description"s>Descrição:</p>
                            <p>{lead.comentario_user}</p>
                        </li> 
                    ))}
                </ul>

                {/*Modal Cadastro de indicação*/}

                <Modal show={showNewCadastro} onHide={handleCloseNC}>
                    <Modal.Header closeButton>
                        <Modal.Title>Cadastrar indicação</Modal.Title>
                    </Modal.Header>
                    <Form onSubmit={handleSubmitNC}>
                        <Modal.Body>

                                <InputGroup hasValidation className="mb-3" >
                                    <div className={!cadastroLead.nome.validated ? "invalid" : ""} focused={focused.nome.toString()}>
                                        <InputGroup.Text className="basic-addon1" id="basic-addon1"><FiUser size={18} strokeWidth={3} /></InputGroup.Text>
                                        <Form.Control
                                        
                                        placeholder="Nome"
                                        aria-label="Nome"
                                        name="nome"
                                        onChange={onChangeNC}
                                        aria-describedby="basic-addon1"
                                        onBlur={handleFocus}
                                        
                                        />
                                    </div>
                                    <Form.Label className="errorMessage">
                                        Preencha o nome por favor
                                    </Form.Label>
                                </InputGroup>
                            
                                <InputGroup  className="mb-3" >
                                    <div className={!cadastroLead.telefone.validated ? "invalid" : ""} focused={focused.telefone.toString()}>
                                        <InputGroup.Text className="basic-addon1" id="basic-addon1"><FiPhone size={18} strokeWidth={3} /></InputGroup.Text>
                                        <InputMask
                                            
                                            placeholder="Telefone"
                                            mask={isCellPhone ? "(99) 99999-9999" : "(99) 9999-9999"}
                                            value={cadastroLead.telefone.value}
                                            name="telefone"
                                            onChange={onChangeNC}
                                            onBlur={handleFocus}
                                            
                                            >
                                        
                                    
                                            
                                            {(inputProps) => (
                                                <FormControl
                                                {...inputProps}
                                                type="text"
                                                
                                                />
                                            )}
                                        </InputMask>
                                    </div>
                                    
                                    <Form.Label className="errorMessage">
                                        {"Preencha com um celular (não esqueça o 9 na frente) ou um telefone fixo válido"}  
                                    </Form.Label>
                                    
                                </InputGroup>
                            

                            
                            <InputGroup  className="mb-3" >
                                <div focused={focused.email.toString()} className={!cadastroLead.email.validated ? "invalid" : ""}>
                                    <InputGroup.Text className="basic-addon1" id="basic-addon1"><FiMail size={18} strokeWidth={3} /></InputGroup.Text>
                                    <Form.Control
                                    
                                    placeholder="E-mail"
                                    aria-label="E-mail"
                                    aria-describedby="basic-addon1"
                                    name="email"
                                    onChange={onChangeNC}
                                    onBlur={handleFocus}
                                    
                                    />    
                                </div>
                                <Form.Label className="errorMessage">
                                    Preencha com um e-mail válido. *Não é obrigatório
                                </Form.Label>
                                </InputGroup>
                            
                            <div className="localization">
                                <Form.Label>Onde esta pessoa busca uma oportunidade?</Form.Label>
                                <div className="localization-option">
                                    <div>
                                        <Form.Select aria-label="Selecione uma opção"
                                            className={!cadastroLead.uf.validated ? "invalid" : ""}
                                            value={cadastroLead.uf.value}
                                            name="uf"
                                            onChange={onChangeNC}
                                            onBlur={handleFocus}
                                            focused={focused.uf.toString()}>
                                            <option>UF</option>
                                            {ufs.map((ufs) => (
                                                <option key={ufs.id} value={ufs.id}>{ufs.label}</option>
                                            ))}
                                        </Form.Select>
                                        <Form.Label className="errorMessage">
                                            UF
                                        </Form.Label>
                                    </div>
                                    
                                    <div>
                                        <Form.Select aria-label="cidade" 
                                        className={!cadastroLead.cidade.validated ? "invalid" : ""}
                                        name="cidade"
                                        value={cadastroLead.cidade.value}
                                        onChange={onChangeNC}
                                        disabled={!cadastroLead.uf.value}
                                        onBlur={handleFocus}
                                        focused={focused.cidade.toString()}>
                                            <option>Cidade</option>
                                            {cities.map((city) => (
                                                <option key={city.id} value={city.nome}>
                                                {city.nome}
                                                </option>
                                            ))}
                                        </Form.Select>
                                        <Form.Label className="errorMessage">
                                            Cidade
                                        </Form.Label>
                                    </div>
                                </div>                                                
                            </div>
                            
                            <div focused={focused.interesse.toString()} className={(cadastroLead.comprar.value === true || cadastroLead.vender.value === true) ? "validationType" : "validationType invalid"}>
                                <Form.Label>O indicado esta interessado em...</Form.Label>
                                <Form.Label className="errorMessage">
                                    Interesse
                                </Form.Label>
                                <Form.Group >
                                    <Form.Check 
                                        type="checkbox"
                                        id="buy"
                                        label="Comprar um imóvel"
                                        name="comprar"
                                        onChange={onChangeNC}
                                    />

                                    <Form.Check
                                        
                                        type="checkbox"
                                        label="Vender um imóvel"
                                        id="sell"
                                        name="vender"
                                        onChange={onChangeNC}
                                    />
                                </Form.Group>
                                
                            </div>
                            <FloatingLabel
                                controlId="floatingTextarea"
                                label="Escreva algum comentário..."
                                className={!cadastroLead.comentario_user.validated ? "mb-3 invalid" : "mb-3"}
                                focused={focused.comentario_user.toString()}
                            >
                                <Form.Control as="textarea" placeholder="Leave a comment here" 
                                
                                name="comentario_user"
                                onChange={onChangeNC} 
                                onBlur={handleFocus}
                                />
                                
                            </FloatingLabel>
                            <Form.Label className="errorMessage">
                                    Escreva algumas palavras, por favor...
                            </Form.Label>  
                            
  
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={handleCloseNC}>
                                Fechar
                            </Button>
                            <Button type="submit" variant="primary" >
                                Salvar
                            </Button>
                        </Modal.Footer>
                    </Form>
                </Modal>

                {/*Modal View Cadastro de usuario*/}

                <Modal show={showCadastro} onHide={handleClose}>
                    <Modal.Header closeButton>
                        <Modal.Title>{leadView.nome}</Modal.Title>
                        <FiX strokeWidth={3} size={18} onClick={handleClose} className="botao-x-modal"/>
                    </Modal.Header>
                    <Form>
                        <Modal.Body>
                            <Form.Group >
                                <InputGroup  className="mb-3" >
                                    <InputGroup.Text className="basic-addon1" id="basic-addon1"><FiPhone size={18} strokeWidth={3} /></InputGroup.Text>
                                    <InputMask
                                        className=""
                                        placeholder={leadView.telefone}
                                        disabled
                                        >
                                        
                                        {(inputProps) => (
                                            <FormControl
                                            {...inputProps}
                                            type="text"
                                            disabled
                                            />
                                        )}
                                    </InputMask>
                                </InputGroup>
                            </Form.Group>

                            <Form.Group >
                                <InputGroup  className="mb-3" >
                                    <InputGroup.Text className="basic-addon1" id="basic-addon1"><FiMail size={18} strokeWidth={3} /></InputGroup.Text>
                                    <Form.Control
                                    placeholder={leadView.email}
                                    aria-label="E-mail"
                                    aria-describedby="basic-addon1"
                                    disabled
                                    />
                                </InputGroup>
                            </Form.Group>
                            <Form.Group className="mb-3">
                            <Form.Label>
                                {"Mensagens"}
                            </Form.Label> 
                                <MessageList messages={leadView.menssagens} />
                            </Form.Group>
                        </Modal.Body>
                        <Modal.Footer className="messenger">
                            <form>
                                <div className="form-group">
                                    <input
                                    type="text"
                                    name="msg"
                                    className="form-control"
                                    placeholder="Digite uma mensagem..."
                                    value={messageToSend}
                                    onChange={(e) => setMessageToSend(e.target.value)}
                                    />
                                </div>
                                <button onClick={handleMessageSubmit} className="btn btn-primary">
                                    <FiSend size={18} strokeWidth={3}/>
                                </button>
                            </form>
                        </Modal.Footer>
                    </Form>
                </Modal>
            </div>
        
    )
}