import React from "react";
import { Helmet } from "react-helmet";

import {Link} from 'react-router-dom';

import logo from '../../assets/logo.svg'
import './styles.css'

export default function NotFound(){

    return (
        <div className="notFound-container">
            <Helmet>
                <title>
                    404 - www.indiqueumimovel.com
                </title>
            </Helmet>
            <img src={logo} alt="Logo da indiqueumimovel.com" />
            <h2>404</h2>
            <h1>Ops... Parece que essa página não existe</h1>
            <Link to={'/'}> CLIQUE AQUI e retorne para home</Link>

        </div>
        
    )
}
