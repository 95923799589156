import React from "react";

import './global.css'

import Rotas from "./routes";


function App() {
   return (
    <Rotas />
  );
}

export default App;
