import React, {useState} from "react";
import { Helmet } from "react-helmet";

import { Link, useNavigate } from "react-router-dom";
import { Button, InputGroup, Form } from 'react-bootstrap';
import api from '../../services/api_db';


import { FiMail } from 'react-icons/fi';
import validateEMAIL from "../../services/validar_email";

import './styles.css';

import logo from '../../assets/logo.svg'


export default function ForgotPass(){
    const navigate = useNavigate();
    const [clicked, setClicked] = useState(false);

    //Rotas
    const routeLogin = '/login';

    
    const [email, setEmail] = useState("");

    const handleEmail = (e)=>{
        setEmail(e.target.value)
    }
    

    async function handleSubmit(e){
        e.preventDefault();
        

        if(validateEMAIL(email) === true){
            setClicked(true)
            await api.post('forgot-pass', {
                "email": email
            })
            alert('Confira sua caixa de e-mail')
            navigate('/login')
        }else(
            alert('Preencha um e-mail valido')
        )

    }
    
    return (
        <div className="ForgotPass-container">
            <Helmet>
                <title>
                    Recuperar Senha - www.indiqueumimovel.com
                </title>
            </Helmet>
            <img className="logo-fp" src={logo} alt="Logo da empresa" />
            <section>
                <form className="form-fp content" onSubmit={(e) => {
                    e.preventDefault();
                    }}>
                    <p>Esqueceu a sua senha? Sem problemas! Digite seu e-mail novamente e clique em solicitar nova senha. 
                        Se estiver correto, você receberá um e-mail com instruções.</p>
                    <InputGroup className="mb-3">
                            <InputGroup.Text className="basic-addon1"><FiMail size={18} strokeWidth={3} /></InputGroup.Text>
                            <Form.Control
                            className="input-mail-fp"
                            placeholder="E-mail"
                            aria-label="Username"
                            aria-describedby="basic-addon1"
                            name="email"
                            onChange={handleEmail}
                            required isInvalid
                            />
                            <Form.Control.Feedback type="invalid">
                                Por favor, preencha o campo de e-mail.
                            </Form.Control.Feedback>      
                    </InputGroup>
                    <div className="d-grid">
                            <Button onClick={handleSubmit} variant="warning" type="Submit" >{clicked ? <span className="loading-fp"></span> :"SOLICITAR NOVA SENHA" }</Button>
                    </div>
                    
                </form>
                
                <Link to={routeLogin}>
                    &#60; Voltar
                </Link>
                
            </section>
        </div>
    )
}