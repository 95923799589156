import React, {useState} from "react";
import { Helmet } from "react-helmet";
import {useLocation, useNavigate} from 'react-router-dom';
import { Button } from 'react-bootstrap';
import api from '../../services/api_db';
import validatePASSWORD from "../../services/validar_senha";

import { FiLock } from 'react-icons/fi';

import './styles.css';

import logo from '../../assets/logo.svg'
import FormInputDark from "../../components/FormInputDark";

export default function RedefinePass(){
    const [clicked, setClicked] = useState(false)
    const [values, setValues] = useState({
        password: {"value":"", "validated": false},
        confirmPassword: {"value":"", "validated": false}
    })

    const navigate = useNavigate();
    const location = useLocation();
    const params = new URLSearchParams(location.search);
    const token = params.get('token'); 
    
    const onChange = (e) => {
        if(e.target.name === "password"){
            setValues({ ...values, [e.target.name]: {"value": e.target.value, "validated": validatePASSWORD(e.target.value)}})
        }if(e.target.name === "confirmPassword"){
            var tOrL = e.target.value === values.password.value
            setValues({ ...values, [e.target.name]: {"value": e.target.value, "validated": tOrL }})
        }
    }
    
    async function handleSubmit(e) {
        e.preventDefault();
        
        
        const data = {
            "password": values.password.value,
            token,
        }

        if( values.password.validated === true &&
            values.confirmPassword.validated === true
            ){
                setClicked(true)
                try{ 
                await api.post('redefine-pass', data);
                alert("Senha redefinida com sucesso")
                navigate('/login')
            }catch(err){
                alert("Token inválido ou expirado, solicite um novo email")
                navigate('/recuperacao-senha');
            }
                        
            }else{
            alert('Por favor, preencha uma senha valida');
        }

    }
    
    return (
        <div className="RedefinePass-container">
            <Helmet>
                <title>
                    Redefinir Senha - www.indiqueumimovel.com
                </title>
            </Helmet>
            <img className="logo-rp" src={logo} alt="Logo da empresa" />
            <section>
                <form className="form-rp content" onSubmit={(e) => {
                    e.preventDefault();
                    }}>
                    <p>Digite uma nova senha</p>
                    <FormInputDark 
                        id={1}
                        className={!values.password.validated ? "invalid" : ""}
                        name="password"
                        type= "password"
                        placeholder= "Nova Senha"
                        errorMessage= "Digite uma senha de 8 digitos com maiusculas, minusculas, número e caracter especial"
                        label= {<FiLock strokeWidth={3} size={18}/>}
                        onChange={onChange}
                    />
                    <FormInputDark 
                        id={1}
                        className={!values.password.validated ? "invalid" : ""}
                        name="confirmPassword"
                        type= "password"
                        placeholder= "Confirme a senha"
                        errorMessage= "Senhas não conferem"                        
                        label= {<FiLock strokeWidth={3} size={18}/>}
                        onChange={onChange}
                    />
                    <div className="d-grid">
                            <Button onClick={handleSubmit} variant="warning" type="Submit" >{clicked ? <span className="loading-rp"></span> :"REDEFINIR SENHA" }</Button>
                    </div>
                    
                </form>
                
                
                
            </section>
        </div>
    )
}