import React, { useEffect, useState } from 'react';
import {Link} from 'react-router-dom';
import api from '../services/api_db'

import {FiUsers, FiFileText, FiChevronsUp, FiChevronsDown, FiSettings, FiPower, FiX, FiDollarSign} from 'react-icons/fi'

export default function MenuMobile({isMenuOpen, setIsMenuOpen}) {

    const [isExpanded, setIsExpanded] = useState(false);
    const [isAdmin, setIsAdmin] = useState(false);
    const userId = localStorage.getItem('userId')

    useEffect(()=>{
        try{
            api.get('isadmin',{
                headers: {
                    'Authorization': userId,
            }})
            .then(response => {
                const isadmin = response.data.oauth;
                setIsAdmin(isadmin);
                
            });
        }catch(err){
            console.log("erro ao obter o username")
        }
    },[])

    useEffect(() => {
        document.body.style.overflowY = isMenuOpen ? 'hidden' : 'auto';
    }, [isMenuOpen])
    
    //Rotas
    const routeIncidents = '/indicacoes';
    const routeReportLeads = '/relatorios/leads';
    const routeReportUsers = '/relatorios/usuarios';
    const routeUser = '/usuario';
    const routeLogOut = '/login';
    const routeValue = '/tabelas';

    return (
        <div className={'menu-mobile ' + (isMenuOpen ? '' : 'menu-mobile-hide')} >
            <FiX size={40} strokeWidth={3} className='x-menu-mobile' onClick={() => setIsMenuOpen(false)}/>
            <Link to={routeIncidents} className='menu-link' onClick={() => setIsMenuOpen(false)}><FiUsers size={25} strokeWidth={3}/> Indicações</Link>    
            <Link to={routeValue} className='menu-link' onClick={() => setIsMenuOpen(false)}><FiDollarSign size={25} strokeWidth={3}/> Valores</Link>    
            {isAdmin && (
                <div className="togle-menu">
                    <p href='#' className='menu-link' onClick={() => setIsExpanded(!isExpanded)}>
                        {isExpanded ? 
                            <span>
                            <FiFileText size={25} strokeWidth={3} /> Relatórios 
                            <FiChevronsUp size={25} strokeWidth={3}/>
                            </span> 
                            :
                            <span>
                                <FiFileText size={25} strokeWidth={3} /> Relatórios 
                                <FiChevronsDown size={25} strokeWidth={3}/>
                            </span>
                        }
                    </p>
                    {isExpanded && (
                        <div >
                            <Link to={routeReportLeads} className='menu-link menu-toggle' onClick={() => setIsMenuOpen(false)}>Lead's</Link>
                            <Link to={routeReportUsers} className='menu-link menu-toggle' onClick={() => setIsMenuOpen(false)}>Usuarios</Link>
                        </div>
                    )}
                </div>
            )}
            <Link to={routeUser} className='menu-link' onClick={() => setIsMenuOpen(false)}><FiSettings size={25} strokeWidth={3}/> Conta</Link>   
            
            <Link className='menu-link' onClick={() => localStorage.clear() } to={routeLogOut}><FiPower size={25} strokeWidth={3}/> Sair</Link>      
        </div>
    )
};