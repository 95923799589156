import React, {useState, useEffect} from "react";

import { useNavigate } from "react-router-dom";

import { InputGroup, Form, Table, Button, Pagination } from "react-bootstrap";
import { FiDownload, FiEye, FiSearch } from "react-icons/fi";
import { CSVLink} from 'react-csv'


import api from '../../../../services/api_db';
import './styles.css'

export default function UsersReport () {

    //constantes
    const [countLeads, setCountLeads] = useState(0)

    const navigate = useNavigate();
    const [isAdmin, setIsAdmin] = useState(false);
    const userId = localStorage.getItem('userId');
    

    
    const handleSearchInputChange = (event) => {
        const pesquisa = event.target.value;
        Pesquisar(pesquisa);
    };

    const [list, setList] = useState([
        { nome: 'Fulano', cidade: 'São Paulo', id: 1, variant: 'warning' },
        { nome: 'Beltrano', cidade: 'Rio de Janeiro', id: 2, variant: 'danger' },
        { nome: 'Ciclano', cidade: 'Porto Alegre',  id: 3, variant: 'secondary' },
        
    ]); 

    
        const [activePage, setActivePage] = useState(1);
        const itemsPerPage = 8;
        const totalPages = Math.ceil(list.length / itemsPerPage);
      
        const handlePageChange = (pageNumber) => {
          setActivePage(pageNumber);
        };
      
        const startIndex = (activePage - 1) * itemsPerPage;
        const endIndex = startIndex + itemsPerPage;
        const paginatedList = list.slice(startIndex, endIndex);

    //Funções
   


    function Pesquisar(string) {
        const tabela = document.getElementsByTagName("tbody")[0];
        const linhas = tabela.getElementsByTagName("tr");
        for (let r = 0; r < linhas.length; r++) {
            const dados = linhas[r].getElementsByTagName("td");
            let encontrei = false;
            for (let d = 0; d < dados.length; d++) {
                if (dados[d].innerText.toLowerCase().indexOf(string.toLowerCase()) >= 0) {
                    encontrei = true;
                    break;
                }
            }
            if (encontrei) {
                linhas[r].style.display = "";
            } else {
                linhas[r].style.display = "none";
            }
        }
    }

    useEffect(()=>{
        setCountLeads(list.length)
    })

    useEffect(()=>{
        try{
            api.get('isadmin',{
                headers: {
                    'Authorization': userId,
            }})
            .then(response => {
                const isadmin = response.data.oauth;
                setIsAdmin(isadmin);
                if(!isadmin){
                    navigate('/404')
                }
            });
        }catch(err){
            
            console.log("erro ao obter o username")
        }
        
    },[])

    useEffect(() => {
        try{
            api.get('users')
            .then(response => {
                const users = response.data;
                setList(users.map((user)=>({
                    "id": user.id_user,
                    "nome": user.nome,
                    "cidade": user.cidade + " - " + user.uf,
                    "celular": user.celular,
                    "nascimento": user.nascimento,
                    "email": user.email,
                    "act_term": user.act_term,
                })))
            });
        }catch(err){
            console.log("erro ao obter os usuarios")
        }
    },[])

    //Componentes
    
    


    return (
        isAdmin ?
        <div className="usersReport-container">
            <div>
                <h1>Usuários</h1>
                <Button variant="warning" >
                    <CSVLink separator=";" data={list}> 
                        <FiDownload strokeWidth={3} size={18}/> EXPORTAR
                    </CSVLink>
                </Button>
                
            </div>
            <div className="search-container">
                <InputGroup className="mb-3" >
                    <InputGroup.Text className="basic-addon1" id="basic-addon1"><FiSearch size={18} strokeWidth={3} /></InputGroup.Text>
                    <Form.Control
                    className="input-login"
                    placeholder="Pesquisar"
                    aria-label="Pesquisar"
                    aria-describedby="basic-addon1"
                    onChange={handleSearchInputChange}
                    />
                </InputGroup>
            </div>
            <div className="count-container">
                <span>total de indicações: <strong>{countLeads}</strong></span>
            </div>

            {/*Lista de indicações*/}   
            <div className="usersReport-content">
                <Table striped bordered hover>
                    <thead>
                    <tr>
                        <th>ID</th>
                        <th>Nome</th>
                        <th>Cidade</th>
                        <th>
                        <div className="d-flex align-items-center justify-content-between">
                            View
                        </div>
                        </th>
                    </tr>
                    </thead>
                    <tbody className="usersReport-tbody">
                    {paginatedList.map((item) => (
                        <tr key={item.id}>
                        <td>{item.id}</td>
                        <td >{item.nome}</td>
                        <td>{item.cidade}</td>
                        <td>
                            <Button variant={item.variant}><FiEye strokeWidth={3}/></Button>
                            
                        </td>
                        </tr>
                    ))}
                    </tbody>
                </Table>
                <Pagination className="justify-content-end">
                    <Pagination.First onClick={() => handlePageChange(1)} />
                    <Pagination.Prev
                    onClick={() =>
                        handlePageChange(activePage === 1 ? activePage : activePage - 1)
                    }
                    />
                    {[...Array(totalPages)].map((_, index) => (
                    <Pagination.Item
                        key={index + 1}
                        active={index + 1 === activePage}
                        onClick={() => handlePageChange(index + 1)}
                    >
                        {index + 1}
                    </Pagination.Item>
                    ))}
                    <Pagination.Next
                    onClick={() =>
                        handlePageChange(
                        activePage === totalPages ? activePage : activePage + 1
                        )
                    }
                    />
                    <Pagination.Last onClick={() => handlePageChange(totalPages)} />
                </Pagination>
            </div>
            
        </div> : null
        
    )
}