import React, { useEffect } from "react";

import { FiX } from 'react-icons/fi';

export default function PdfView({isPdfOpen, setIsPdfOpen, embedPdf}){

    useEffect(() => {
        document.body.style.overflowY = isPdfOpen ? 'hidden' : 'auto';
    }, [isPdfOpen]);

    return(
        <div className={(isPdfOpen ? 'pdf-view-container' : 'pdf-view-container-hidden')}>
            <FiX size={40} strokeWidth={3} className='x-pdf-view' onClick={() => setIsPdfOpen(false)}/>
            <span className="pdf-view">{embedPdf}</span>
            <span className="loading"></span>
        </div>
    );
    
};