export default function capitalizeWords(texto) {
    const palavras = texto.split(" ");
    const palavrasCapitalizadas = palavras.map((palavra) => {
      if (palavra.length > 3) {
        return palavra.charAt(0).toUpperCase() + palavra.slice(1).toLowerCase();
      } if(palavra.length <= 2){
        return palavra.toLowerCase();
      } else {
        return palavra;
      }
    });
    
    return palavrasCapitalizadas.join(" ");
}