export default function validatePASSWORD(senha) {
    
        // Verificar se a senha não contém espaço em branco
        if (senha.match(/\s/)) {
          return false;
        }
      
        // Verificar se a senha contém pelo menos 1 número
        if (!senha.match(/\d/)) {
          return false;
        }
      
        // Verificar se a senha contém pelo menos 1 letra maiúscula
        if (!senha.match(/[A-Z]/)) {
          return false;
        }
      
        // Verificar se a senha contém pelo menos 1 letra minúscula
        if (!senha.match(/[a-z]/)) {
          return false;
        }
      
        // Verificar se a senha contém pelo menos 1 caractere especial
        if (!senha.match(/[^A-Za-z0-9]/)) {
          return false;
        }
      
        // Se a senha passou em todos os critérios, retorna true
        return true;
      
    
    
}