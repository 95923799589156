import React, {useState, useEffect} from "react";
import { Helmet } from "react-helmet";
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import TagManager from "react-gtm-module";

import api from '../../services/api_db';

import Header from "../../components/Header";
import Menu from "../../components/Menu";
import MenuMobile from "../../components/MenuMobile";

import './styles.css';


export default function Home(){
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [activeIndex, setActiveIndex] = useState();
    const location = useLocation();
    const navigate = useNavigate();

    const userId = localStorage.getItem('userId');
    const [userName, setUserName] = useState(localStorage.getItem('userName'));

    useEffect(()=>{
        if(!userId){
            navigate('/login')
        }else{
            if(!userName){
                try{
                    api.get('users')
                    .then(response => {
                        const user = response.data.find(user => user.id_user === userId);
                        setUserName(user ? user.nome.split(' ')[0] : '');
                        localStorage.setItem("userName", user ? user.nome.split(' ')[0] : '' )
                        if(user.nome === ""){
                            alert("Você precisa completar seus Dados Pessoais para começar a indicar")
                            navigate('/usuario')
                        }
                    });
                }catch(err){
                    console.log("erro ao obter o username")
                }
            }}
    }, [userName])

    useEffect(() => {
        console.log(document.title)
        TagManager.dataLayer({
          dataLayer: {
            event: 'visualizacao-de-pagina',
            page: document.title
          }
          
        });
    }, []);
    
    return(
        userId ?
        <div className="app-container">
            <Helmet>
                <title>
                    Home - www.indiqueumimovel.com
                </title>
            </Helmet>
            <Header setIsMenuOpen={setIsMenuOpen} userName={userName} setActiveIndex={setActiveIndex} />
            <MenuMobile isMenuOpen={isMenuOpen} setIsMenuOpen={setIsMenuOpen}/>
            <Menu activeIndex={activeIndex} setActiveIndex={setActiveIndex}/>
            {location.pathname === '/' ? 
            <div className="home-container">
                <h1>Bem-vindo à Plataforma!</h1>
                <p>{"que vc tenha um excelente dia ;)"}</p>
            </div> 
            : 
            <Outlet />}
        </div> : null
    )
}