export default function validateEMAIL(email) {
    let str;
    //extrai usuari e dominio
    if(email===""){
        str = "vazia"
        
    }else{
        str = email
        
    }
    
    const usuario = str.substring(0, str.indexOf("@"));
    const dominio = str.substring(str.indexOf("@")+ 1, str.length);
    
    
    if ((usuario.length >=1) &&
        (dominio.length >=3) &&
        (usuario.search("@")===-1) &&
        (dominio.search("@")===-1) &&
        (usuario.search(" ")===-1) &&
        (dominio.search(" ")===-1) &&
        (dominio.search(".")>=0) &&
        (dominio.indexOf(".") >=1)&&
        (dominio.lastIndexOf(".") < dominio.length - 1)) {
        return true
    }else{
        return false
    }
}