import React from 'react';
import { HashRouter, Navigate, Route, Routes } from 'react-router-dom';

import Login from './pages/Login';
import ForgotPass from './pages/ForgotPass';
import Register from './pages/Register';
import Incidents from './pages/Home/Incidents';
import IncidentsReport from './pages/Home/Reports/Incidents';
import UsersReport from './pages/Home/Reports/Users';
import Home from './pages/Home';
import User from './pages/Home/User';
import RedefinePass from './pages/RedefinePass';
import NotFound from './pages/404';
import Sheets from './pages/Home/Sheets';

export default function Rotas() {
    return(
      <>
        <HashRouter>
          <Routes>
              <Route path='/' exact element={<Home />}>
                <Route path='/indicacoes' element={<Incidents />} />
                <Route path='/relatorios/usuarios' element={<UsersReport />} />
                <Route path='/relatorios/leads' element={<IncidentsReport />} />
                <Route path='/usuario' element={<User />} />
                <Route path='/tabelas' element={<Sheets />} />
              </Route>
              <Route path='/404' element={<NotFound />} />
              <Route path='/recuperar-senha' element={<RedefinePass />} />
              <Route path='/login' element={<Login />} />
              <Route path='/recuperacao-senha' element={<ForgotPass />} />
              <Route path='/cadastro' element={<Register />} />
              <Route path='*' element={<Navigate to={'/404'}/>} />
          </Routes>
        </HashRouter>
        
      </>  
    );
}