export default function validateBIRTHDAY(dataDeAniversario){
    // Verificar se a string não está vazia
    if (!dataDeAniversario) {
        return false;
    }

    // Converter a string de data para um objeto Date
    var data = new Date(dataDeAniversario);

    // Verificar se a data é válida
    if (isNaN(data.getTime())) {
        return false;
    }

    // Extrair o dia, mês e ano da data de aniversário
    var dia = data.getDate() + 1;
    var mes = data.getMonth() + 1; // Adicionar 1 porque o índice dos meses começa em 0
    var ano = data.getFullYear();

    // Calcular a data de aniversário da pessoa quando completar 18 anos
    var dataDeAniversarioDe18Anos = new Date(ano + 18, mes - 1, dia);

    // Obter a data atual
    var hoje = new Date();

    // Comparar a data atual com a data de aniversário de 18 anos da pessoa
    if (hoje >= dataDeAniversarioDe18Anos) {
        return true;
    } else {
        return false;
    }
}