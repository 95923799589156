import React, { useState } from 'react';
import {IMaskInput} from 'react-imask';
import { Form } from 'react-bootstrap';

import './styles.css';

export default function FormInputDark(props) {
    const [focused,  SetFocused] = useState(false)
    const{label, errorMessage, mask, onChange, id, placeholder, ...inputProps} = props;
    
    const handleFocus = (e) => {
        SetFocused(true);
    }
    
    

    return(
        <div className="formInput">
            <label className='label'>{placeholder}</label>
            <label className="iconeInput"  htmlFor={id}>{label}</label>
            <Form.Control
                as={IMaskInput} 
                mask={mask} 
                id={id} 
                placeholder={placeholder} 
                {...inputProps} 
                onChange={onChange} 
                required
                onBlur={handleFocus}
                focused={focused.toString()}
                
                
                />
            <span>{errorMessage}</span>
        </div>
    )
}