import React, { useState, useEffect } from "react";
import { useNavigate } from 'react-router-dom';

import { InputGroup, Form, Table, Button, Pagination } from "react-bootstrap";
import { FiDownload, FiEye, FiSearch } from "react-icons/fi";
import { CSVLink} from 'react-csv'

import api from '../../../../services/api_db'
import './styles.css';


export default function IncidentsReport () {
    //constantes
    const [countLeads, setCountLeads] = useState(0);

    const navigate = useNavigate();
    const [isAdmin, setIsAdmin] = useState(false);
    const userId = localStorage.getItem('userId');
    
    const handleSearchInputChange = (event) => {
        const pesquisa = event.target.value;
        Pesquisar(pesquisa);
    };

    const [list, setList] = useState([
        { nome: 'Fulano', estado: "SP", cidade: 'São Paulo', interesse: 'Compras', indic: 'A', id: 1, variant: 'warning' },
        { nome: 'Beltrano', estado: "RJ", cidade: 'Rio de Janeiro', interesse: 'Viagens', indic: 'O', id: 2, variant: 'danger' },
        { nome: 'Ciclano', estado: "RS", cidade: 'Porto Alegre', interesse: 'Esportes', indic: 'O', id: 3, variant: 'secondary' }
    ]);

    
        const [activePage, setActivePage] = useState(1);
        const itemsPerPage = 8;
        const totalPages = Math.ceil(list.length / itemsPerPage);
      
        const handlePageChange = (pageNumber) => {
          setActivePage(pageNumber);
        };
      
        const startIndex = (activePage - 1) * itemsPerPage;
        const endIndex = startIndex + itemsPerPage;
        const paginatedList = list.slice(startIndex, endIndex);

    //Funções

    function Pesquisar(string) {
        const tabela = document.getElementsByTagName("tbody")[0];
        const linhas = tabela.getElementsByTagName("tr");
        for (let r = 0; r < linhas.length; r++) {
            const dados = linhas[r].getElementsByTagName("td");
            let encontrei = false;
            for (let d = 0; d < dados.length; d++) {
                if (dados[d].innerText.toLowerCase().indexOf(string.toLowerCase()) >= 0) {
                    encontrei = true;
                    break;
                }
            }
            if (encontrei) {
                linhas[r].style.display = "";
            } else {
                linhas[r].style.display = "none";
            }
        }
    }

    useEffect(()=>{
        setCountLeads(list.length)
    })

    useEffect(()=>{
        
        try{
            api.get('isadmin',{
                headers: {
                    'Authorization': userId,
            }})
            .then(response => {
                const isadmin = response.data.oauth;
                setIsAdmin(isadmin);
                if(!isadmin){
                    navigate('/404')
                }
            });
        }catch(err){
            console.log("erro ao obter o username")
        }
       
        
    },[])

    useEffect(() => {
        try{
            api.get('all_leads')
            .then(response => {
                const leads = response.data;
                setList(leads.map((lead)=>({
                    "id": lead.id,
                    "nome": lead.nome,
                    "indic": lead.id_user,
                    "cidade": lead.cidade + " - " + lead.uf,
                    "interesse":    lead.comprar === 1 && lead.vender === 1 ? "Comprar, Vender" : "" || 
                                    lead.comprar === 1 ? "Comprar" : "" ||
                                    lead.vender === 1 ? "Vender" : "",
                    "telefone": lead.telefone,
                    "email": lead.email,
                    "status": lead.status,
                    "data_at": lead.data_at,
                    "comentario_user": lead.comentario_user,
                })))
            });
        }catch(err){
            console.log("erro ao obter os leads")
        }
    },[])

    return ( isAdmin ?
        <div className="incidentsReport-container">
            <div>
                <h1>Leads</h1>
                <Button variant="warning" >
                    <CSVLink separator=";" data={list}> 
                        <FiDownload strokeWidth={3} size={18}/> EXPORTAR
                    </CSVLink>
                </Button>
                    
            </div>
            <div className="search-container">
                <InputGroup className="mb-3" >
                    <InputGroup.Text className="basic-addon1" id="basic-addon1"><FiSearch size={18} strokeWidth={3} /></InputGroup.Text>
                    <Form.Control
                    className="input-login"
                    placeholder="Pesquisar"
                    aria-label="Pesquisar"
                    aria-describedby="basic-addon1"
                    onChange={handleSearchInputChange}
                    />
                </InputGroup>
                
            </div>
            <div className="count-container">
                        <span>total de indicações: <strong>{countLeads}</strong></span>
            </div>

            {/*Lista de indicações*/}   
            <div className="incidentsReport-content">
                <Table striped bordered hover>
                    <thead>
                    <tr>
                        <th>Nome</th>
                        <th className="hide-mobile">Cidade</th>
                        <th>Interesse</th>
                        <th>ID</th>
                        <th>
                        <div className="d-flex align-items-center justify-content-between">
                            View
                        </div>
                        </th>
                    </tr>
                    </thead>
                    <tbody className="incidentsReport-tbody">
                    {paginatedList.map((item) => (
                        <tr key={item.id}>
                        <td>{item.nome}</td>
                        <td className="hide-mobile">{item.cidade}</td>
                        <td>{item.interesse}</td>
                        <td>{item.indic}</td>
                        <td>
                            <Button variant={item.variant}><FiEye strokeWidth={3}/></Button>
                        </td>
                        </tr>
                    ))}
                    </tbody>
                </Table>
                <Pagination className="justify-content-end">
                    <Pagination.First onClick={() => handlePageChange(1)} />
                    <Pagination.Prev
                    onClick={() =>
                        handlePageChange(activePage === 1 ? activePage : activePage - 1)
                    }
                    />
                    {[...Array(totalPages)].map((_, index) => (
                    <Pagination.Item
                        key={index + 1}
                        active={index + 1 === activePage}
                        onClick={() => handlePageChange(index + 1)}
                    >
                        {index + 1}
                    </Pagination.Item>
                    ))}
                    <Pagination.Next
                    onClick={() =>
                        handlePageChange(
                        activePage === totalPages ? activePage : activePage + 1
                        )
                    }
                    />
                    <Pagination.Last onClick={() => handlePageChange(totalPages)} />
                </Pagination>
            </div>
        </div> : null
        
    )
}
