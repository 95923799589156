import React, { useState, useEffect } from "react";
import './styles.css';
import logo from './../../assets/logo.svg';
import { Helmet } from "react-helmet";
import TagManager from 'react-gtm-module';


import PdfView from "../../components/PdfView";
import FormInputDark from "../../components/FormInputDark/";

import validatePHONE from "../../services/validar_phone";
import validateEMAIL from "../../services/validar_email";
import validatePASSWORD from "../../services/validar_senha";
import validateBIRTHDAY from "../../services/validar_birthday";
import api from '../../services/api_db';

import { FiCalendar, FiLock, FiMail } from "react-icons/fi";
import { BsWhatsapp } from "react-icons/bs"
import { Link, useNavigate } from "react-router-dom";



export default function Register(){
    //rotas
    const routeLogin = "/login"
    const navigate = useNavigate()

    //termos
    const [isPdfOpen, setIsPdfOpen] = useState(false);
    const [embedPdf, setEmbedPdf] = useState('');
    const [acceptedTerms, setAcceptedTerms] = useState(false);

    


    //values
    const [values, setValues] = useState({
        email: {"value":"", "validated": false},
        cellphone: {"value":"", "validated": false},
        birthday: {"value":"", "validated": false},
        password: {"value":"", "validated": false},
        confirmPassword: {"value":"", "validated": false}
    })

    

    //inputs
    const inputs = [
        {
            id: 1,
            name: "email",
            type: "email",
            placeholder: "E-mail",
            errorMessage: "Preencha com um e-mail valido",
            label: <FiMail strokeWidth={3} size={18}/>,
            required: true,
            
            
        },
        {
            id: 2,
            name: "cellphone",
            type: "text",
            placeholder: "Celular",
            errorMessage: "Preencha com um telefone válido, de preferencia WhatsApp",
            label: <BsWhatsapp strokeWidth={1} size={18}/>,
            mask: "(00) 00000-0000",
        },
        {
            id: 3,
            name: "birthday",
            type: "date",
            placeholder: "Aniversário",
            errorMessage: "Você precisa ter mais de 18 anos para se cadastrar",
            label: <FiCalendar strokeWidth={3} size={18}/>,
            
        },
        {
            id: 4,
            name: "password",
            type: "password",
            placeholder: "Senha",
            errorMessage: "Digite uma senha de 8 digitos com maiusculas, minusculas, número e caracter especial",
            label: <FiLock strokeWidth={3} size={18}/>,
            
            
        },
        {
            id: 5,
            name: "confirmPassword",
            type: "password",
            placeholder: "Confirme sua senha",
            errorMessage: "Senhas não conferem",
            label: <FiLock strokeWidth={3} size={18}/>,
            
            
        }
    ]
    
    
    //Funções
    

    async function handleSubmit(e) {
        e.preventDefault();
        const data = {
            "usuario": values.email.value,
            "celular": values.cellphone.value,
            "senha": values.password.value,
            "nascimento": values.birthday.value,
        }

        if(values.email.validated === true &&
            values.cellphone.validated === true &&
            values.birthday.validated === true &&
            values.password.validated === true &&
            values.confirmPassword.validated === true &&
            acceptedTerms === true
            ){
            try {
                const response = await api.post('new_user', data);
                if(!response.data.id_user){
                    alert("Usuario já cadastrado! Se você não lembra da senha clique em 'Esqueci minha senha' na tela de login.");
                    navigate("/login");
                }else{
                    alert(`cadastro feito com sucesso. ID: ${response.data.id_user}`);
                    navigate("/login");
                }
                
            }catch(err){
                alert("Erro ao cadastrar. Tente novamente mais tarde.")
                console.log(err)
            }
        }else{
            alert('Por favor, preencha todos os campos corretamente e aceite os termos.');
        }
    }

    const onChange = (e) => {

        if(e.target.name === "email"){
            setValues({ ...values, [e.target.name]: {"value": e.target.value, "validated": validateEMAIL(e.target.value)}})
        }if(e.target.name === "cellphone"){
            setValues({ ...values, [e.target.name]: {"value": e.target.value, "validated": validatePHONE(e.target.value)}})
        }if(e.target.name === "birthday"){
            setValues({ ...values, [e.target.name]: {"value": e.target.value, "validated": validateBIRTHDAY(e.target.value)}})
        }if(e.target.name === "password"){
            setValues({ ...values, [e.target.name]: {"value": e.target.value, "validated": validatePASSWORD(e.target.value)}})
        }if(e.target.name === "confirmPassword"){
            var tOrL = e.target.value === values.password.value
            setValues({ ...values, [e.target.name]: {"value": e.target.value, "validated": tOrL }})
        }
        
        
    }

    
    useEffect(() => {
        console.log(document.title)
        TagManager.dataLayer({
          dataLayer: {
            event: 'visualizacao-de-pagina',
            page: document.title
          }
          
        });
    }, []);
    
    return(
        <div className="register-container">
            <Helmet>
                <title>
                    Cadastro - www.indiqueumimovel.com
                </title>
            </Helmet>
            <img src={logo} alt="logo do indique um imovel ponto com" />
            <form>
                {inputs.map((input) =>(
                    <FormInputDark 
                        className={!values[input.name].validated ? "invalid" : ""} key={input.id} {...input} value={values[input.name].value} onChange={onChange}/>
                ))}
                <span>
                    <input name="checkbox" type="checkbox" onChange={(e) => setAcceptedTerms(e.target.checked)}/> 
                    &nbsp;&nbsp;Li e aceito os&nbsp;
                    <Link onClick={() => setIsPdfOpen(true) + setEmbedPdf(
                        <iframe title="termos e condições" src="https://drive.google.com/file/d/1M3DxoSA5JEvmtZgpVY00aqhp3BYjD9ex/preview" width="640" height="480" allow="autoplay"></iframe>                        )}>
                        termos e condições 
                    </Link>
                    &nbsp;e a&nbsp;
                    <Link onClick={() => setIsPdfOpen(true) + setEmbedPdf(
                        <iframe title="politica de dados" src="https://drive.google.com/file/d/1XZRFHF31AUp98WqaX1egLcX6mxubkVTM/preview" width="640" height="480" allow="autoplay"></iframe>
                    )}>politica de dados</Link>
                </span>
                    
                
                <button type="submit" onClick={handleSubmit} >Cadastrar</button>
            </form>
            <Link to={routeLogin}>&#60; Voltar</Link>
            <PdfView isPdfOpen={isPdfOpen} setIsPdfOpen={setIsPdfOpen} embedPdf={embedPdf}/> 
        </div> 
    )
} 