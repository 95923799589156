import React from "react";
import './styles.css';

import {FaPaperPlane, FaPlane, FaRocket} from 'react-icons/fa';

export default function Sheets(){
    const cards_data = [
        {
            "id": 1,
            "icon": <FaPaperPlane size={40}/>,
            "name": "Light",
            "reward": "R$ 1.000,00",
            "min_value": "R$ 138.060,00",
            "max_value": "R$ 552.239,99"
        },
        {
            "id": 2,
            "icon": <FaPlane size={40} style={{ transform: 'rotate(315deg)' }}/>,
            "name": "Standart",
            "reward": "R$ 2.000,00",
            "min_value": "R$ 552.240,00",
            "max_value": "R$ 1.380.599,99"
        },
        {
            "id": 3,
            "icon": <FaRocket size={40} />,
            "name": "Heavy",
            "reward": "R$ 3.000,00",
            "min_value": "R$ 1.380.600,00",
            "max_value": ""
        }
    ]

    function Cards(props){
        
        if(!props.max_value){
            return (
                <div className="card">
                    <div className="title">
                        <span>{props.icon}</span>
                        <h3>{props.name}</h3>
                    </div>
                    <div className="price">
                        <p>ganhe</p>
                        <h2>{props.reward + "*"}</h2>
                    </div>
                    <div className="condition">
                        <p>Indicando a compra ou venda apartir de</p>
                        <p className="value">
                            <strong>{props.min_value}</strong>
                        </p>
                        
                        
                    </div>
                </div>
            )
        }else{
            return (
                <div className="card">
                    <div className="title">
                        <span>{props.icon}</span>
                        <h3>{props.name}</h3>
                    </div>
                    <div className="price">
                        <p>ganhe</p>
                        <h2>{props.reward + "*"}</h2>
                    </div>
                    <div className="condition">
                        <p>Indicando a compra ou venda de</p>
                        <p className="value">
                            <strong>{props.min_value}</strong>
                        </p>
                        <p>à</p>
                        <p className="value">
                            <strong>{props.max_value}</strong>
                        </p>
                    </div>
                </div>
            )
        }
            
        

    }

    return (
        <div className="sheets-container">
            <div>
                {cards_data.map((index) => (
                    <Cards {...index}/>   
                ))}
            </div>
            
            <p className="ps">*Valores pagos após a efetivação da venda e do recebimento da comissão integral e sujeitos a retenção de impostos vigentes. </p>
        </div>
    )
}