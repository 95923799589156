import React, { useState } from "react";
import { Helmet } from "react-helmet";

import { Link, useNavigate } from "react-router-dom";

import api from '../../services/api_db';

import { Button, InputGroup, Form } from 'react-bootstrap';
import { FiMail, FiEye, FiEyeOff, FiLock } from 'react-icons/fi';

import logo from '../../assets/logo.svg';
import campanha from '../../assets/campanha.svg';
import './styles.css';




export default function Login() {
    const [type, setType] = useState('password');
    const [icon, setIcon] = useState(<FiEyeOff size={18} strokeWidth={3} color="#212529"/>)
    const [usuario, setUsuario] = useState('');
    const [senha, setSenha] = useState('');

    const navigate = useNavigate();
  
    const handleClick = () => {
        setType(type === 'password' ? 'text' : 'password');
        setIcon(React.isValidElement(icon) && icon.type === FiEye ? <FiEyeOff size={18} strokeWidth={3} color="#212529"/> : <FiEye size={18} strokeWidth={3} color="#ffc107"/>);
    };

    async function handleSubmit(e){
        e.preventDefault();
        try{
            const response = await api.post('session', {usuario, senha});
            localStorage.setItem('userId', response.data.id_user);

            navigate('/');
        }catch(err){
            alert('Usuário e/ou senha não correspondem');
        }
    }

    return (
        <div className="login-container">
            <Helmet>
                <title>
                    Login - www.indiqueumimovel.com
                </title>
            </Helmet>
            <img className="logo"src={logo} alt="logo da vendasuacasa.com" />
            <img className="campanha" src={campanha} alt="Junte-se a nós" />
            <section className="form">
                <form className="form-login" onSubmit={handleSubmit}>
                    <InputGroup className="mb-3">
                        <InputGroup.Text className="basic-addon1" id="basic-addon1"><FiMail size={18} strokeWidth={3} /></InputGroup.Text>
                        <Form.Control
                        className="input-login"
                        placeholder="Usuário"
                        aria-label="Username"
                        aria-describedby="basic-addon1"
                        onChange={(e) => setUsuario(e.target.value)}
                        />
                    </InputGroup>
                    <InputGroup className="mb-3">
                        <InputGroup.Text className="basic-addon1" id="basic-addon1"><FiLock size={18} strokeWidth={3}/></InputGroup.Text>
                        <Form.Control
                        className="input-login arretar"
                        placeholder="Senha"
                        aria-label="Password"
                        type={type}
                        aria-describedby="basic-addon1"
                        onChange={(e) => setSenha(e.target.value)}
                        />
                        <Button variant="outline-secondary" className="button-addon2" onClick={handleClick}>
                            {icon}
                        </Button>
                    </InputGroup>
                    
                    <Link to="/recuperacao-senha" className="forgot-pass">Esqueci minha senha</Link>
                    
                    <div className="d-grid">
                        <Button variant="warning" type="Submit">ENTRAR</Button>
                    </div>
                    <div className="cadastro">
                        <p>Não tem uma conta? <Link to="/cadastro">Cadastre-se</Link></p>
                    </div>                    
                </form>
            </section>
        </div>
        
    )
}