import React from "react";

import logo from '../assets/logo.svg';
import {FiPower, FiMenu } from 'react-icons/fi';
import { Link } from 'react-router-dom';


export default function Header( { setIsMenuOpen, setActiveIndex, userName } ){
    //Rotas
    const routeLogOut = '/login';

    const handleClick = (index) => {
        setActiveIndex(index);
    }

    return (

        <header className="header-app">
            <Link onClick={() => handleClick("")} to={'/'}><img className="header-logo" src={logo} alt="Logo do indique um imóvel .com" /></Link>
            <div className="header-text">
                <span >Olá {userName}</span>
                
                <Link onClick={() => localStorage.clear() } to={routeLogOut}><FiPower size={18} strokeWidth={3}/></Link>
                
            </div>
            <p className='button-menu-mobile' onClick={() => setIsMenuOpen(true)}>
                <FiMenu size={40} strokeWidth={3}/>
            </p>
        </header>
          
    )
}