export default function validatePHONE(phone) {
    //extrai os dígitos do Phone
    const num1 = phone.charAt(1);
    const num2 = phone.charAt(2);
    const num3 = phone.charAt(5);
    const num4 = phone.charAt(6);
    const num5 = phone.charAt(7);
    const num6 = phone.charAt(8);
    const num7 = phone.charAt(9);
    const num8 = phone.charAt(11);
    const num9 = phone.charAt(12);
    const num10 = phone.charAt(13);
    const num11 = phone.charAt(14);

    const ddd = [11, 12, 13, 14, 15, 16, 17, 18, 19, 
      21, 22, 24, 27, 28, 
      31, 33, 34, 35, 37, 38, 
      41, 42, 43, 44, 45, 46, 47, 48, 49, 
      51, 53, 54, 55, 
      61, 62, 63, 64, 65, 66, 67, 68, 69,
      71, 73, 74, 75, 77, 79,
      81, 82, 83, 84, 85, 86, 87, 88, 89,
      91, 92, 93, 94, 95, 96, 97, 98, 99
    ]
    
    //Validação dos phones inválidos conhecidos

    //ddd correto?
    if (!ddd.includes(parseInt(phone.substring(1, 3)))) {
      return false;
    }
    //9 para celular ou 3 a 5 para fixo
    if (num3 !== '9' && (num3 < '2' || num3 > '5')) {
      return false;
    }
    // verifica se o último dígito é um número, se o terceiro dígito for 9
    if (num3 === '9' && isNaN(parseInt(num11))) {
      return false;
    }

    // verifica se o num10 é um número e num11 não contém um número, se o terceiro dígito for de 3 a 5
    if ((num3 >= '3' && num3 <= '5') && (isNaN(parseInt(num10)) || !isNaN(parseInt(num11)))) {
        return false;
    }
    
    // converte os dígitos do telefone em um array de números inteiros
    const digits = [num1, num2, num3, num4, num5, num6, num7, num8, num9, num10, num11].map(d => parseInt(d));

    // verifica se o número de telefone é uma sequência ascendente ou descendente
    let sequenceLength = 1;
    let lastDigit = digits[0];
    for (let i = 1; i < digits.length; i++) {
      let digit = digits[i];
      if (digit === lastDigit + 1 || digit === lastDigit - 1) {
          sequenceLength++;
      } else {
          sequenceLength = 1;
      }
      if (sequenceLength >= 8) {
          return false;
      }
      lastDigit = digit;
    }
    // verifica se o número contém 8 ou mais dígitos repetidos
    

    for (let i = 0; i < phone.length; i++) {
      let count = 0;
      for (let j = 0; j < phone.length; j++) {
          if (phone[i] === phone[j]) {
              count++;
          }
      }
      if (count >= 8) {
          return false;
      }
    }


    return true


  }